<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" class="text-c_brown" />
    <div class="p-4">
      <router-link :to="{ name: 'resources' }">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left" />
        Volver a Recursos sobre COIL
      </router-link>
    </div>
    <div class="md:grid md:grid-cols-2">
      <article
        v-for="resource in sortedResources"
        :key="resource.name"
        class="p-4 mb-6"
      >
        <a
          class="block border-b pb-1"
          :href="resource.url"
          :title="resource.name"
          target="_blank"
        >
          <p>{{ resource.name }}</p>
          <p class="text-xs text-c_darkblue mt-2">{{ resource.authors }}</p>
          <p class="text-right text-xs">{{ resource.year }}</p>
        </a>
      </article>
    </div>
  </section>
</template>

<style scoped>
article a {
  @apply text-c_lightblue;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "ArticlesView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Artículos sobre COIL",
      urlEN: "https://coil.unam.mx/en/#/resources/articles",
      resources: [
        {
          name: "Beneficios, inconvenientes y retos de los cursos COIL: las experiencias de los académicos",
          year: 2022,
          authors: "Ramírez Ramírez, A., & Bustos Aguirre, M. L.",
          url: "https://doi.org/10.54674/ess.v34i2.579",
        },
        {
          name: "Collaborative Online International Learning (COIL): Preparedness and experiences of South African students",
          year: 2021,
          authors: "Naicker, A., Singh, E., & van Genugten, T.",
          url: "https://doi.org/10.1080/14703297.2021.1895867",
        },
        {
          name: "Collaborative online international learning between Spain and Mexico: a microlearning experience to enhance creativity in complexity",
          year: 2022,
          authors:
            "Romero-Rodríguez, J.-M., Ramirez-Montoya, M. S., Glasserman-Morales, L. D., & Ramos Navas-Parejo, M.",
          url: "https://www.emerald.com/insight/0040-0912.htm",
        },
        {
          name: "Collaborative online international learning: A way to develop students’ engineering capabilities and awareness to become global citizens",
          year: 2022,
          authors:
            "Munoz-Escalona, P., Cassier de Crespo, Z., Olivares Marin, M., & Dunn, M.",
          url: "https://doi.org/10.1177/0306419020934100",
        },
        {
          name: "Developing communication confidence and professional identity in chemistry through international online collaborative learning	",
          year: 2018,
          authors: "Skagen, D., McCollum, B., Morsch, L., & Shokoples, B.",
          url: "https://doi.org/10.1039/C7RP00220C",
        },
        {
          name: "Evaluation of a Collaborative Online International Learning (COIL): A food product analysis and development project",
          year: 2024,
          authors:
            "West, H., Goto, K., Borja, S. A. N., Trechter, S., & Klobodu, S.",
          url: "https://doi.org/10.1080/15528014.2022.2069441",
        },
        {
          name: "Exploring U.S. students’ takeaways from a cross-Pacific COIL project",
          year: 2022,
          authors: "Jiang, X.",
          url: "https://doi.org/10.21827/jve.5.36443",
        },
        {
          name: "Facilitated COIL conversational model: a virtual exchange between a private university in the US and a teacher college in South Sudan",
          year: 2022,
          authors:
            "Bloomquist, C. D., Hobson, C. J., Ayaga, J., Trott, C., Suiter, S., & Freeman, A. D.",
          url: "https://doi.org/10.21827/jve.5.38668",
        },
        {
          name: "Global citizenship cultivation through the COIL-PBL model: case study of the Great Debates course",
          year: 2021,
          authors: "Tuke, G., Kapur, S., & Ashour, K.",
          url: "https://doi.org/10.21827/jve.4.35815",
        },
        {
          name: "Global citizenship education (GCE) in internationalisation: COIL as alternative Thirdspace",
          year: 2021,
          authors: "Guimarães, F. F., & Finardi, K. R.",
          url: "https://doi.org/10.1080/14767724.2021.1875808",
        },
        {
          name: "Global Shared Learning Classroom Model: A Pedagogical Strategy for Sustainable Competencies Development in Higher Education",
          year: 2022,
          authors:
            "Membrillo-Hernández, J., Bejarano, W. J. C., Manzano, L. A. M., Caratozzolo, P., & Villegas, P. V.",
          url: "https://doi.org/10.3991/ijep.v13i1.36181",
        },
        {
          name: "Increasing global mindset through collaborative online international learning (COIL): internationalizing the undergraduate international business class",
          year: 2022,
          authors: "Garcia, F., Smith, S. R., Burger, A., & Helms, M.",
          url: "https://www.emerald.com/insight/2046-469X.htm",
        },
        {
          name: "Influences of academic culture in Collaborative Online International Learning (COIL): Differences in Mexican and U.S. students' reported experiences",
          year: 2020,
          authors: "King Ramírez, C.",
          url: "https://doi.org/10.1111/flan.12485",
        },
        {
          name: "Integrating Metaliteracy into the Design of a Collaborative Online International Learning (COIL) Course in Digital Storytelling",
          year: 2021,
          authors: "Mackey, T. P., & Aird, S. M.",
          url: "https://doi.org/10.55982/openpraxis.13.4.442",
        },
        {
          name: "Internationalization at Home: Enhancing Global Competencies in the EFL Classroom through International Online Collaboration",
          year: 2023,
          authors: "Simões, A. V., & Sangiamchit, C.",
          url: "https://doi.org/10.3390/educsci13030264",
        },
        {
          name: "Los entornos virtuales como promotores de pertenencia a las instituciones educativas: el caso del CIDI de la UNAM",
          year: 2021,
          authors: "Castillo, M. E. R., & Fonseca, A.",
          url: "https://doi.org/10.22201/fa.2007252Xp.2021.23.80064",
        },
        {
          name: "Online Collaborative Learning Activities: The Perceptions of Culturally Diverse Graduate Students",
          year: 2017,
          authors: "Kumi-Yeboah, A., Dogbey, J., & Yuan, G.",
          url: "https://eric.ed.gov/?id=EJ1163472",
        },
        {
          name: "Overview of the Impact of Collaborative Online International Learning on Learners",
          year: 2023,
          authors: "Liu, Y.",
          url: "https://doi.org/10.1051/shsconf/202315704011",
        },
        {
          name: "The efectiveness of Collaborative Online International Learning (COIL) on intercultural competence development in higher education",
          year: 2023,
          authors:
            "Hackett, S., Janssen, J., Beach, P., Perreault, M., Beelen, J., & van Tartwijk, J.",
          url: "https://doi.org/10.1186/s41239-022-00373-3",
        },
        {
          name: "The Impact of International Virtual Exchange on Participation in Education Abroad",
          year: 2021,
          authors: "Lee, J., Leibowitz, J., & Rezek, J.",
          url: "https://doi.org/10.1177/10283153211052777",
        },
        {
          name: "Transforming Perspectives Through Virtual Exchange: A US-Egypt Partnership Part 1",
          year: 2022,
          authors:
            "Wood, E. A., Collins, S. L., Mueller, S., Stetten, N. E., & El-Shokry, M.",
          url: "https://doi.org/10.3389/fpubh.2022.877547",
        },
        {
          name: "Virtual mobility for all",
          year: 2020,
          authors: "Järvenpää, J., & Szymaszek, P.",
          url: "https://www.unite-university.eu/unitenews/virtual-mobility-for-all",
        },
        {
          name: "Without Crossing a Border: Exploring the Impact of Shifting Study Abroad Online on Students’ Learning and Intercultural Competence Development during the COVID-19 Pandemic",
          year: 2021,
          authors: "Liu, Y., & Shirley, T.",
          url: "https://doi.org/10.24059/olj.v25i1.2471",
        },
        {
          name: "Collaborative international online learning for the development of intercultural awareness: an experience with pre-service language teachers",
          year: 2024,
          authors: "Huertas-Abril, C. A., & Palacios-Hidalgo, F. J.",
          url: "http://dx.doi.org/10.1108/JME-09-2023-0093",
        },
        {
          name: "Role of faculty in collaborative online international learning (COIL) – a pedagogical tool for Internationalization at Home (IaH)",
          year: 2025,
          authors: "Grover, P., Phutela, N. & Yadav, M.",
          url: "http://dx.doi.org/10.1108/JARHE-04-2023-0141",
        },
      ],
    };
  },
  computed: {
    sortedResources() {
      const resourcesCopy = this.resources.slice();
      resourcesCopy.sort((a, b) => b.year - a.year);
      return resourcesCopy.sort((a, b) => {
        if (a.year < b.year) {
          return 1;
        } else if (a.year > b.year) {
          return -1;
        } else {
          if (a.authors < b.authors) {
            return -1;
          } else if (a.authors > b.authors) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    },
  },
};
</script>
