<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" class="text-c_brown" />
    <div class="p-4">
      <router-link :to="{ name: 'resources' }">
        <font-awesome-icon icon="fa-solid fa-circle-chevron-left" />
        Volver a Recursos sobre COIL
      </router-link>
    </div>
    <div class="md:grid md:grid-cols-2">
      <article
        v-for="resource in sortedResources"
        :key="resource.name"
        class="p-4 mb-6"
      >
        <a
          class="block border-b pb-1"
          :href="resource.url"
          :title="resource.name"
          target="_blank"
        >
          <p>{{ resource.name }}</p>
          <p class="text-xs text-c_darkblue mt-2">{{ resource.authors }}</p>
          <p class="text-right text-xs">{{ resource.year }}</p>
        </a>
      </article>
    </div>
  </section>
</template>

<style scoped>
article a {
  @apply text-c_lightmagenta;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "BooksView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "Libros sobre COIL",
      urlEN: "https://coil.unam.mx/en/#/resources/books",
      resources: [
        {
          name: "Race After Technology",
          authors: "Ruha benjamin",
          year: 2019,
          url: "https://www.ruhabenjamin.com/race-after-technology",
        },
        {
          name: "Virtual Internationalization in Higher Education. Innovative University: Digital -- International -- Transformative",
          year: 2020,
          authors: "Bruhn, Elisa",
          url: "https://eric.ed.gov/?id=ED610050",
        },
        {
          name: "Teaching, Technology, and Teacher Education during the COVID-19 Pandemic: Stories from the Field",
          year: 2020,
          authors:
            "Richard E. Ferdig (Editor), Emily Baumgartner (Editor), Richard Hartshorne (Editor), Regina Kaplan-Rakowski (Editor), Chrystalla Mouza (Editor)",
          url: "https://experts.illinois.edu/en/publications/teaching-technology-and-teacher-education-during-the-covid-19-pan",
        },
        {
          name: "De la crisis a la oportunidad: Internacionalización de la educación superior después de la COVID-19",
          year: 2022,
          authors:
            "Santiago Castiello-Gutiérrez, Martín P. Pantoja Aguilar, César Eduardo Gutiérrez Jurado",
          url: "https://rcientificas.uninorte.edu.co/index.php/esal/article/view/14921",
        },
      ],
    };
  },
  computed: {
    sortedResources() {
      const resourcesCopy = this.resources.slice();
      resourcesCopy.sort((a, b) => b.year - a.year);
      return resourcesCopy.sort((a, b) => {
        if (a.year < b.year) {
          return 1;
        } else if (a.year > b.year) {
          return -1;
        } else {
          if (a.authors < b.authors) {
            return -1;
          } else if (a.authors > b.authors) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    },
  },
};
</script>
