<template>
  <section id="jornada" class="jornada">
    <header class="jornada__header">
      <img
        src="@/assets/images/jornada2th/2aCOIL-header.jpg"
        alt="Jornada COIL Header"
        class="jornada__header--image full"
      />
      <img
        src="@/assets/images/jornada2th/2aCOIL-header-min.jpg"
        alt="Jornada COIL Header"
        class="jornada__header--image mobile"
      />
    </header>
    <div class="jornada__hero">
      <div class="jornada__hero--title">
        <img
          src="@/assets/images/jornada2th/2aCOIL-title.jpg"
          alt="2a Jornada COIL"
          width="400"
        />
      </div>
      <div class="jornada__hero--dates">
        <h2>NOV 26-27</h2>
        <p>Escuela Nacional <strong>de Estudios Superiores Mérida</strong></p>
        <p>
          Centro Peninsular <strong>en Humanidades y Ciencias Sociales</strong>
        </p>
        <p><strong>Mérida, Yucatán</strong></p>
        <p>
          Institución invitada: <strong>Universidad Autónoma de Yucatán</strong>
        </p>
      </div>
      <div class="jornada__hero--figure">
        <img
          src="@/assets/images/jornada2th/2aCOIL-figure.jpg"
          alt="Figura 2a Jornada COIL"
          width="400"
        />
      </div>
    </div>
    <div class="jornada__description">
      <p>
        Esta segunda Jornada exploramos el mundo del aprendizaje colaborativo
        internacional en línea (COIL, por sus siglas en inglés) desde diversos
        ángulos y tenemos algo especial para todos:
      </p>
      <ol>
        <li>
          Dos conferencias magistrales y dos talleres a cargo de dos expertos
          internacionales en este tipo de experiencias educativas: la Dra.
          Stephanie Doscher (U. de Minnesota, EUA) y el Dr. Osvaldo Succi Junior
          (centro Paula Souza, Brasil).
        </li>
        <li>
          Mesa de diálogo que explora con docentes y funcionarios los factores
          de éxito en cinco entidades.
        </li>
        <li>
          Una presentación de estudiantes COILers que ilustran sus vivencias y
          desafíos.
        </li>
        <li>
          Talleres presenciales de nivel introductorio e intermedio, así como
          dos en línea.
        </li>
        <li>
          Mesa que explora buenas prácticas de gestión y coordinación en cinco
          entidades.
        </li>
        <li>Una reflexión final</li>
      </ol>
      <p>
        Esta riqueza de perspectivas se dará en el marco de la hermosa ciudad de
        Mérida, en dos entidades universitarias que abren sus brazos a este
        evento: la Escuela Nacional de Estudios Superiores Unidad Mérida y el
        Centro Peninsular en Humanidades y Ciencias Sociales UNAM.
      </p>
      <p>
        Es un evento híbrido, por lo que será posible participar de forma
        presencial (hay cupo para 100 participantes) y en línea (con capacidad
        hasta para 300 participantes).
      </p>
      <p class="mt-8">El programa preliminar detallado es el siguiente:</p>
    </div>
    <div class="jornada__schedule">
      <div class="jornada__schedule--day">
        <h3>26 DE NOVIEMBRE 2024</h3>
        <p class="text-sm my-6">
          Escuela Nacional de Estudios Superiores Unidad Mérida, ubicada en
          Tablaje Catastral N° 6998, Carretera Mérida-Tetiz Km. 4.5, Municipio
          de Ucú, Yucatán, México, C. P. 97357
        </p>
        <div
          class="jornada__schedule--day_item"
          v-for="(item, index) in nov26"
          :key="index"
        >
          <div class="jornada__schedule--day_item-time">{{ item.time }}</div>
          <div
            class="jornada__schedule--day_item-activity"
            v-html="item.activity"
          ></div>
        </div>
      </div>
      <div class="jornada__schedule--day">
        <h3>27 DE NOVIEMBRE 2024</h3>
        <p class="text-sm my-6">
          Centro Peninsular en Humanidades y Ciencias Sociales UNAM (CEPHCIS),
          en Calle 43 SN x 44 y 46, colonia Industrial, Mérida Yucatán, México,
          C.P. 97150
        </p>
        <div
          class="jornada__schedule--day_item"
          v-for="(item, index) in nov27"
          :key="index"
        >
          <div class="jornada__schedule--day_item-time">{{ item.time }}</div>
          <div
            class="jornada__schedule--day_item-activity"
            v-html="item.activity"
          ></div>
        </div>
      </div>
    </div>
    <div class="jornada__callout">
      <carousel-merida />
      <!--<div class="jornada__callout-register">
        <h3>Registro para participación en línea</h3>
        <p>
          <a
            href="https://www.unaminternacional.unam.mx/coil_registro"
            target="_blank"
            class="jornada__callout-register--button"
            >Registrate aquí</a
          >
        </p>
        <h3 class="mt-20">Mayores informes</h3>
        <p>
          <a
            href="mailto:jornada.coilunam@global.unam.mx"
            class="jornada__callout-register--link"
            >jornada.coilunam@global.unam.mx</a
          >
        </p>
      </div>-->
    </div>
  </section>
</template>

<style>
.jornada {
  @apply min-h-screen w-full pt-4 text-gray-50;
  background-color: #593d65;
}
.jornada__header {
  @apply container mx-auto;
}
.jornada__header--image {
  @apply w-10/12 xl:w-8/12 mx-auto;
}
.jornada__header--image.full {
  @apply hidden md:block;
}
.jornada__header--image.mobile {
  @apply w-10/12 sm:w-8/12 block md:hidden;
}
.jornada__hero {
  @apply w-full sm:grid sm:grid-cols-2 lg:grid-cols-4 content-center justify-center my-8;
}
.jornada__hero--title {
  @apply content-center;
}
.jornada__hero--title img {
  @apply w-8/12 sm:w-1/2 mx-auto;
}
.jornada__hero--figure {
  @apply my-8 pl-10 flex justify-end sm:order-2;
}
.jornada__hero--figure img {
  @apply text-clip;
}
.jornada__hero--dates {
  @apply content-center text-center my-20 sm:order-3 sm:col-span-2 sm:my-10 lg:order-2 lg:my-0;
}
.jornada__hero--dates h2 {
  @apply text-4xl mb-6;
}
.jornada__hero--dates p {
  @apply block mx-auto w-2/3 lg:w-full 2xl:w-1/3 lg:text-sm px-6 lg:px-0 2xl:px-6 py-2 border-b border-gray-50 mb-2;
}
.jornada__description,
.jornada__schedule {
  @apply container mx-auto p-4;
}
.jornada__schedule {
  @apply xl:grid xl:grid-cols-2 gap-8;
}
.jornada__schedule--day h3 {
  @apply my-4;
}
.jornada__schedule--day_item {
  @apply border border-c_2a_orange mb-4 pb-2;
}
.jornada__schedule--day_item-time {
  @apply py-1 px-4 bg-c_2a_orange;
}
.jornada__schedule--day_item-activity {
  @apply p-4;
}
.jornada__schedule--day_item-activity ul {
  @apply list-disc list-inside;
}
.jornada__callout {
  @apply container mx-auto p-4 lg:grid lg:grid-cols-2 lg:gap-8 py-20;
}
.jornada__callout-register {
  @apply p-4 mt-20 lg:mt-0 lg:text-right;
}
.jornada__callout-register--button {
  @apply bg-c_2a_orange hover:bg-c_lightorange my-6 py-2 text-center inline-block w-2/3 md:w-2/5 rounded-sm;
}
.jornada__callout-register--link {
  @apply hover:underline my-6 block text-sm;
}
</style>
<script>
import nov26 from "../components/Jornada2th/nov26.json";
import nov27 from "../components/Jornada2th/nov27.json";
import CarouselMerida from "../components/Jornada2th/Carousel.vue";
export default {
  name: "Jornada2thView",
  components: {
    CarouselMerida,
  },
  mounted() {
    this.anchoVentana = window.innerWidth;
  },
  data() {
    return {
      anchoVentana: 0,
      nov26,
      nov27,
    };
  },
};
</script>
